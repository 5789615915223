import S1 from  './weavelifec1.jpg'
import BasicExample from './signup'
import s1 from './DeepLearning.png'
import s2 from './dlscreen2.png'
import s3 from './dlscreen2.png'
import dlNotes from '../src/DL UNIT 1-4.pdf'
//import AndroidNotes from '../src/Android_CompleteNotes.pdf'


import Image from './ImageSlide'
const East = ()=>{
 return(

     <div style={{backgroundColor:"white"}}>
        <Image image1={s1} image2={s2} image3={s3} />
        <h1 style={{color:"black",textAlign:"center", textShadow:"1px 1px 20px yellow"}}>preview</h1>

        <BasicExample name={dlNotes} value="DeepLearning" type="Download" image={S1}/>   



     </div>
    


 )



    }
    export default East;