import React from "react";
import BasicExample from './signup'
import Image1 from './kgf1.jpg'
import Image2 from './kgf2.jpg'
import Weave from './weavelife3.jpg'
import sub1 from './english_communication_bodyimage (1).jpg'

import sub2 from './M1.jpg'
import sub3 from './chemfinal.jpg'
import sub4 from './aphys.jpg'
import sub18 from './ultimatec.jpg'
import sub5 from './M2.jpg'
import sub6 from './coa.jpg'
import sub7 from './What-is-Data-Structures-and-Algorithms.jpg'
import sub8 from './Os.jpg'
import sub9 from './software-engineering-chart-with-keywords-and-icons-on-blackboard-G9R7A.jpg'
import sub10 from './Unit I - Graph Theory - Mathematical Foundation of Computer Science.jpg'
import sub11 from './probabilty and ststistics.jpg'
import sub12 from './Database-Management-System.jpg'
import sub13 from './Rprogramming.jpg'
import sub14 from './Machinelearning.jpg'
import sub15 from './cn.jpg'
import sub16 from './Daa.jpg'
import sub17 from './DeepLearning.png'
import html from './html-tagst.jpg'
import sun18 from './cs_mathematicalfoundationsofcomputing_cs103.jpg'
import sub19 from './toc.jpeg'


import pdf from './AP_09_Education_09-07-2024.pdf'
import BasicExample2 from "./login";



const Card=()=>{
return(

    <div style={{backgroundColor:"white"}}>

        <BasicExample2 Image={sub1} url="/eng" value="English" type="Download" text="English "/>
         <BasicExample2 Image={sub2} url="/math" value="Mathematics1" type="Download" text="mathematics "/>
          <BasicExample2 Image={sub3} url="/Chem" value="chemistry" type="Download" text="chemistry "/>
          <BasicExample2 Image={sub18} url="/East" value="C language" type="Download" text="C language"/>
          <BasicExample2 Image={sub4} url="/physics" value="Engineering Physics" type="Download" text="Engineering Physics "/>
         <BasicExample2 Image={sub6} url="/coa" value="COA" type="download" text="Computer Organization Architecture "/>
         <BasicExample2 Image={sub7} url="/dsa" value="DataStructures" type="Download" text="DataStructures "/>
         <BasicExample2 Image={sub8} url="/os" value="OperatingSystem" type="Download" text="OperatingSystems "/>
        <BasicExample2 Image={sub9} url="/soft" value="SoftwareEngineering" type="Download" text="SoftwareEngineering"/>
         <BasicExample2 Image={sub10} url="/mfc" value="MFC" type="Download" text="MfC "/>
         <BasicExample2 Image={sub11} url="/ps" value="Probabilty" type="Download" text="Probabilty "/>
         <BasicExample2 Image={sub12} url="/hjhf" value="DBMS" type="Download" text="DBMS "/>
         <BasicExample2 Image={sub13} url="/hjhf" value="Rprogramming" type="Download" text="RProgramming "/>
         <BasicExample2 Image={sub14} url="/ml" value="machineLearning" type="Download" text="MachineLearning "/>
          <BasicExample2 Image={sub15} url="/cn" value="computerNetworks" type="Download" text="computerNetworks "/>
          <BasicExample2 Image={sub17} url="/dl" value="DeepLearning" type="Download" text="DeepLearning "/>
          <BasicExample2 Image={sub19} url="/flat" value="FLAT/TOC" type="Download" text="FLAT"/>
         



    </div>




)



}
export default Card;