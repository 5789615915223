import React from "react"
import waveoptics1 from './WaveOptics1.png'
import waveoptics2 from './waveoptics2.png'
import newtonring from './Newtonsring.png'
import newtonrings2 from './newtonsring2.png'
import diffraction from './diffraction.png'
import diffraction1 from './diffraction1.png'
import diffraction2 from './diffraction2.png'
import diffraction3 from  './diffraction3.png'
import polarization from './polarization.png'
import dielectrics from './dielectrics.png'
import dielectrics2 from './dielectrics2.png'
import dielectrics3 from './dielctics3.png'
import ferroelectrics from './ferroelectrpcity.png'
import ferroelectrics2 from './ferroelectricity2.png'
import softhardmagnetics from './soft and hard magnetics.png'
import softhardmagnets2 from './softand hard magnetic2.png'
import ferromagnetic from './ferromagnetic.png'
import ferromagnetic2 from './ferromagnetic2.png'


const Physics = () => {
  return (
    <>
        <div className="learning">
          <div className="sidebar">
     <ol>
     <li><a>Subject</a>
     <ul>
        <li>Chapter-1(WaveOptics)</li>
        <li><a href="#Waveoptics1">Introduction</a></li><hr/>
     <li><a href="#NewtonsRing">Newton'sRing</a></li><hr/>
     <li><a href="#Diffraction">Diffraction</a></li><hr/>
     <li><a href="#Polarization">Polarization</a></li><hr/>
     

     <li>Unit2</li>
     <li><a href="#Dielectrics">Dielectrics</a></li><hr/>
     <li><a href="#Ferroelectrics">Ferroelectrics</a></li><hr/>
     <li><a href="#Softhard">Soft &Hard Magnetism</a></li><hr/>
     <li><a href="#Ferromagnetic">Ferromagnetic</a></li><hr/>
     <li><a href="#fuelcells">Fuelcells</a></li><hr/>
     <li><a href="#corrosion">Corrosion</a></li><hr/>
     <li><a href="#Galvanicseries">GalvanicSeries</a></li><hr/>
     <li><a href="#Galvaniccorrosion">Galvanic corrosion</a></li><hr/>
     <li><a href="#Specialpaints">specialpaints</a></li><hr/>
     <li>Unit3</li>
     <li><a href="#AdvanceConcepts">SomeAdvanceConcepts </a></li><hr/>
     <li><a href="#Rotaxenesdef">rotaxenes</a></li><hr/>
     <li><a href="#AdvanceConcepts2">MoleculeSwitches</a></li><hr/>
     
     



     </ul>
    
     </li><hr/>
     </ol>

      </div>
      <div className="learnsubjects">
        <h1 id="introduction" style={{textAlign:"center",fontFamily:"cursive"}}>Welcome to Physics</h1>
        
<a id="Waveoptics1" href={waveoptics1} download>  <img src={waveoptics1} />click to download</a> <hr/>
<hr/>
<a  href={waveoptics2} download>  <img src={waveoptics2} />click to download</a> <hr/>
<hr/>
<a  id="NewtonsRing" href={newtonring} download>  <img src={newtonring} />click to download</a> <hr/>
<hr/>
<a   href={newtonrings2} download>  <img src={newtonrings2} />click to download</a> <hr/>
<hr/>
<a  id="Diffraction" href={diffraction} download>  <img src={diffraction} />click to download</a> <hr/>
<hr/>
<a   href={diffraction1} download>  <img src={diffraction1} />click to download</a> <hr/>
<hr/>
<a   href={diffraction2} download>  <img src={diffraction2} />click to download</a> <hr/>
<hr/>
<a   href={diffraction3} download>  <img src={diffraction3} />click to download</a> <hr/>
<hr/>
<a id="Polarization"  href={polarization} download>  <img src={polarization} />click to download</a> <hr/>
<hr/>
<a id="Dielectrics"  href={dielectrics} download>  <img src={dielectrics} />click to download</a> <hr/>
<hr/>
<a   href={dielectrics2} download>  <img src={dielectrics2} />click to download</a> <hr/>
<hr/>
<a   href={dielectrics3} download>  <img src={dielectrics3} />click to download</a> <hr/>
<hr/>
<a id="Ferroelectrics"  href={ferroelectrics} download>  <img src={ferroelectrics} />click to download</a> <hr/>
<hr/>
<a   href={ferroelectrics2} download>  <img src={ferroelectrics2} />click to download</a> <hr/>
<hr/>
<a id="Softhard"  href={softhardmagnetics} download>  <img src={softhardmagnetics} />click to download</a> <hr/>
<hr/>
<a   href={softhardmagnets2} download>  <img src={softhardmagnets2} />click to download</a> <hr/>
<hr/>
<a id="Ferromagnetic"  href={ferromagnetic} download>  <img src={ferromagnetic} />click to download</a> <hr/>
<hr/>
<a   href={ferromagnetic2} download>  <img src={ferromagnetic2} />click to download</a> <hr/>
<hr/>




      </div>
      </div>
      </>
    
  )
}

export default Physics