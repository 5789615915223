import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import WeaveLife from './weavelife3.jpg'
import { Search } from '@mui/icons-material';


function OffcanvasExample() {
  return (
    <>
      {['sm'].map((expand) => (
        <Navbar key={expand} expand={expand} style={{
          padding:"0px",
        }} className="bg-body-tertiary  mb-0">
          <Container fluid style={{
           position:"relative",
           //backgroundColor:"rgba(65, 26, 266, 0.7)",
           backgroundColor:"rgba(266,266,266,0.1)",
           backdropFilter:"blur(10px)",
           
            padding:"10px",
            top:0,

          }}>
            <Navbar.Brand href="#">WeaveLife</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title   id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={WeaveLife} alt='thumb' height='50px' width='40px' /> WeaveLife 
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body  
              >
                <Nav  id="Navelements"className="justify-content-end flex-grow-1 pe-3" >
                  <Nav.Link href="/home" >Home</Nav.Link>
                  <Nav.Link href="/Learn">Learn..</Nav.Link>
                  <Nav.Link href="/cards">Materials</Nav.Link>
                  <Nav.Link href="/read">login</Nav.Link>

                  <NavDropdown  className='dropm'  style={{textOverflow:"initial",
                   
                  }}
                    title="branch"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/cse">CSE</NavDropdown.Item>                   
                     <NavDropdown.Divider />

                    <NavDropdown.Item href="/ai">
                      CSE(AI & Cs)
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/eee">
                      EEE
                    </NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href="/mech">
                      MECh
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/ece">
                      ECE
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="civil">
                      CIVIL
                    </NavDropdown.Item>
                    <NavDropdown.Divider />


                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"

                    placeholder="Search ..."
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;