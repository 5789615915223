import React from "react";
import mechanicalproperties from './mc properties.png'
import themalvsthermo from './thermal vs thermal setting.png'
import thermal2 from './thermal vs thermal setting2.png'
import compress from './compressmould.jpg'
import injmould from './injmould.jpg'
import blow from './Blowmoulding.jpg'
import extrusion from './extrusion.jpg'
import pvc from './pvc.png'
import pc from './polycarbonate.jpg'
import hydrogen from './hydrogenelectrode.png'
import chemscreen from './chemscreen1.png'
import lion from './lithium ion.png'
import fuel from './fuel cells.png'
import corrosion from './corrosion.png'
import galvaniccorrosion  from './galvanicseries.png'
import galvanicseries from './galvanicseries.png'
import specialpaints from './specialpaints.png'
import advanceConcepts from './advanceconcepts.png'
import rotaxenes3 from './rotaxenes3.png'
import rotaxenes from './rotaxenedef.png'
import advanceconcept2 from './advanceconcepts2(molecule).png'
import  advance from './advanceconceptsrotaxenes.png'
const Chem=()=>{
return(


  <>
  
  
  <div className="learning">
          <div className="sidebar">
     <ol>
     <li><a>Subject</a>
     <ul>
        <li>Chapter-1</li>
        <li><a href="#introduction">Introduction</a></li><hr/>
     <li><a href="#polymerization">Polymerization</a></li><hr/>
     <li><a href="#Mechanicalproperties">mechanicalproperties</a></li><hr/>
     <li><a href="#plastics">plastics</a></li><hr/>
     <li><a href="#tpts">thermoplastics&thermosettingplastics</a></li><hr/>
     <li><a href="#compoundplastics">compoundplastics </a></li><hr/>
     <li><a href="#recyclinge">Recycling e-waste</a></li><hr/>
     <li><a href="#pvc">pvc</a></li><hr/>
     <li><a href="#frp"> Fibre Reinforced plastic</a></li><hr/>
     <li><a href="#naturalrubber">naturalRubber</a></li><hr/>
     <li><a href="#biodegradable">biodegradablepolymers</a></li><hr/>
     <li><a href="#biopolymers">Biopolymers</a></li><hr/>
     <li><a href="#conductingpolymers">conductingpolymers</a></li><hr/>


     

     <li>Unit2</li>
     <li><a href="#hydrogenelectrode">Hydrogen-Electrode</a></li><hr/>
     <li><a href="#calomel Electrode">Calomelelectrode</a></li><hr/>
     <li><a href="#drycell">Drycell</a></li><hr/>
     <li><a href="#lithiumion">lithiumion</a></li><hr/>
     <li><a href="#fuelcells">Fuelcells</a></li><hr/>
     <li><a href="#Corrosion">Corrosion</a></li><hr/>
     <li><a href="#Galvanicseries">GalvanicSeries</a></li><hr/>
     <li><a href="#Galvaniccorrosion">Galvanic corrosion</a></li><hr/>
     <li><a href="#Specialpaints">specialpaints</a></li><hr/>
     <li>Unit3</li>
     <li><a href="#AdvanceConcepts">SomeAdvanceConcepts </a></li><hr/>
     <li><a href="#Rotaxenesdef">rotaxenes</a></li><hr/>
     <li><a href="#AdvanceConcepts2">MoleculeSwitches</a></li><hr/>
     
     



     </ul>
    
     </li><hr/>
     </ol>

      </div>
      <div className="learnsubjects">
        <h1 id="introduction">welcome to chemistry</h1>
        <pages><p>Polymer: Polymers are large molecules made up of many smaller molecules. 'Poly' means many and 'mer' means units.
Monomers: Small molecules of low molecular weight, which combine to give a polymer, are called monomers.
(mono = one, mer = unit) monomers are joined together through polymerisation to form polymers. A polymer contains
hundreds of thousands of monomers.
Homo polymer: a polymer, in which a single type of monomer is used, is called homo polymer.
Examples : Polythene, polystyrene, Polyvinylchloride etc.
Copolymer: A polymer in which the monomers are more than one type is called copolymer (heteropolymer).
 Examples : Buna-S is a copolymer of 1,3- butadiene and styrene.
 Bakelite is a copolymer of phenol and formaldehyde.
Degree of polymerization:
The number of repeating units (or) monomer units available in the polymer is known as degree of polymerization. Polymers
with a high degree of polymersation are called High polymers.
Polymers with low degree of polymerization are called Oligo polymers
Functionality:
The number of bonding sites (or) reactive sites (or) functional groups present in the monomer is called functionality.
When the functionality of monomer is two; it is bifunctional and Linear straight chain polymer is formed.
Examples for bifunctional monomers: a) ethylene b) styrene c) vinyl chloride d) vinyl cyanide.
When the functionality of monomer is three; it is tri-functional and three- dimensional net work polymer is formed.
Ex: phenol, glycerol.
when a trifunctional monomer is mixed in small amounts with a bifunctional monomer, a branched chain polymer is
formed.
Classification of polymers: Based on their sources they are classified into;
1)Natural polymers: The polymers, which are obtained from natural sources such as plants and animals, are called natural
polymers. Eg: Wood, starch, cellulose, Jute, Cotton, Wool, Silk, Proteins, Natural rubber etc. 
2)Synthetic polymers: These are synthesized with the help of chemicals in industries 
E.g.: polythene, nylon-6, 6, synthetic rubber etc. 
3)Semi synthetic polymers: These are the synthetic derivatives of the natural polymers. 
E.g.: Cellulose acetate (Rayon) and cellulose nitrate. 
Classification based on structure: 
a) Linear Polymers: These polymers consist of long and straight chains. 
b) Branched chain polymers: These polymers contain linear chains having some branches. 
c) Cross linked polymers (or) 3- dimensional network polymers: 
Classification Based on Composition of Polymers: [1] Homopolymer [2] Copolymer 
Classification Based on Backbone of the polymer chain: [1] Organic polymer [2] Inorganic Polymers. 
 A polymer whose backbone chain is essentially made of carbon atoms is termed as organic polymer. 
If chain backbone contains no carbon atom is called inorganic polymer. Glass and silicone rubber are examples.Polymerisation: The process of formation of polymers from respective monomers is termed as Polymerization. 
Addition polymerization: During the polymerization process, if the polymer is formed without loss of small molecules 
like water, ammonia etc; then this type of polymerization is called Addition polymerization. It is also known as Chain 
polymerization. Ex: Polyethylene (PE); Polystyrene (PS); Polyvinylchloride (PVC), Neoprene etc.
Condensation polymerization: During the polymerization process, if the polymer is formed with loss of small molecules 
like water, ammonia etc; then this type of polymerization is called condensation polymerization. It is also known as Step 
polymerization. Ex: Polycarbonates, Thiokol, Nylon-6,6, Terylene and Bakelite etc. 
</p><hr/>
 <h1 id="polymerization"> Write about emulsion polymerization and suspension polymerisation method. Give its advantages. 
 (or) Explain the Methods of Polymerization (or) Technology of Polymerization?</h1>  
 <p>
 1) Suspension (Bead or Pearl) polymerization: 
This polymerization occurs in heterogeneous system. The water insoluble monomer is suspended in water as tiny droplets 
by continuous agitation. The droplets are prevented from coalescing by using small quantities of water- soluble polymers 
such as polyvinyl alcohol or colloids. The polymerization is made to occur in each droplet of the monomer using a catalyst. 
The reaction mass is heated to initiate the polymerization. After the completion of polymerization pearl like polydispersed 
polymer mixture is obtained. 
Advantages: 
1. The viscosity build up of polymer is negligible. 
2. Isolation of product is easy as it needs only filtration and washing. 
3. High purity products. 
4. The process is more economical since water is used. 
5. Isolated products need no further purification. 
6. Efficient thermal control. 
Disadvantages: 
1. This method is applicable only for water insoluble monomers. 
2. It is difficult to control particle size. 
Application: This technique is used for the production of polyvinyl acetate, poly styrene, styrene-divinyl benzene etc. 
2) Emulsion polymerization: In this method emulsion of water insoluble monomer and water is prepared and is stabilized 
by the addition of surface acting agents (surfactants) such as soap. Polymerization is initiated by the addition of watersoluble initiator such as potassium persulphate. After adding the initiator, the system is kept agitated in the absence of 
oxygen at 70oc. 
Mechanism: The surfactant has hydrophilic head and hydrophobic tail. The water- soluble initiator links to the hydrophilic 
end whereas the monomer is linked to the hydrophobic end. At a little higher concentration it gets dispersed. When the 
concentration of surfactant exceeds critical micelle concentration (cmc), the soap molecule form micelle (aggregation of 50-
100 molecules) oriented with tails inwards and head outwards. Now, an initiator molecule at the polar end diffuses into the 
micelle to initiate the polymerization process. As the polymerization progresses, there will be depletion in the number of 
monomers within the micelle. They are replenished by the medium. This continues till the polymer formed is big enough to 
come out, the process is terminated by combination. The pure polymer is isolated from the emulsion by the addition of deemulsifier. 
Advantages: 
1. The rate of polymerization is high. 
2. Easy heat control. 
3. A very high molecular weight polymer is obtained. 
4. Molecular weight control is possible. 
5. Viscosity build up is low. 
Disadvantages: Polymer needs purification. 
Application: This method is used for the production of PVC, Poly vinyl acetate etc. 

</p>
<h1 id="Mechanicalproperties"> Explain the Mechanical properties of Polymers?</h1>
<p>

Mechanical properties of Polymers:
 1.Strength: 
 Strength of the polymer depends upon the intermolecular attractive forces. 
 Greater is the attractive forces; higher is the strength of the polymer. 
 Strength of the polymer increases with increasing molecular weight of the polymer or increasing polar groups 
such as –OH, -COOH, -OCH3, -COOR & -X.
 So, the lower molecular weight polymers have less strength, soft and gummy. 
 In cross linked polymers, the polymer chains are strongly linked to each other by covalent bonds which cause 
higher strength, toughness, brittleness etc. 
 Strength of the polymer depends on the shape of the molecule also. 
 If the shape of the molecule is simple and uniform, polymer molecule has less strength. 
 Ex: In PVC, large Cl atoms are present on alternative carbon atoms in the polymer. 
 These Cl atoms and strong attractive forces restrict the movement of molecules in the polymer. 
 So, PVC is tougher and stronger polymer. 
 In poly ethylene attractive forces are weak due to simple structure. Thus, PE is weaker than PVC. 
 2.Elastic character: 
 Rubber is an elastomer, when applied stress is removed; the polymer gets original shape in case of elastomers. 
 In an elastomer, polymer chains are randomly arranged, coiled chains with slight cross links. 
 When the polymer is stretched, the cross links begin to disentangle and straight out. 
 As a result, chains are regularly arranged which cause hardness, stiffness and crystallinity due to increase of the 
attractive forces between the chains. 
 3.Plastic deformation: 
 This is found in thermoplastics. 
 In thermoplastics, polymer chains are close to each other with weak attractive forces. 
 On applying heat or pressure or both, these forces become weak and the polymer chains start moving over each 
other. 
 This results in attaining desired shape by passing the material into the mould. 
 On further cooling, the attractive forces are restabilised and the shape of the material is fixed. 
 Incase of thermosetting polymers, shape of the plastic material cann’t be changed by applying heat or pressure.
 Because the polymer chains are strongly held together by strong covalent cross linkages and there is no 
movement of polymer chains. 
 4.Structure and electrical properties: 
 Most of the plastic materials are good insulators. 
 They are able to withstand to very small current. 
 The insulating property will breakdown in a sufficiently strong field. 
 In non polar polymers, only electronic polarization is responsible for dielectric constant. 
 Dielectric strength is defined as the electric strength which an insulating material can withstand. 
 In polar polymers, both electronic as well as dipole polarization contribute towards overall dielectric constant. 
www.Jntufastupdates.com
 Unit I Polymer Technology Page 4 
 5.Heat effect on polymers: 
 Heat greatly affects the properties of polymers. 
 Amorphous polymers do not possess sharp melting points. 
 At very low temperature, the amorphous material exists as glassy materials, which have lack of mobility in 
polymer molecules. 
 If the polymer is heated, it eventually becomes soft and flexible. 
 This happens only at the glass transition temperature.

<h2 style={{textAlign:"center"}}> MECHANICAL PROPERTIES</h2>
<a href={mechanicalproperties} download>  <img src={mechanicalproperties} />click to download</a> 

</p><hr/>
<h1 id="plastics">PLASTICS: </h1>
The word plastic itself comes from the Greek word plasticos, which means to be able to be shaped or moulded by heat. 
Advantages of plastics over other traditional materials (like wood, metals, glass etc): 
1) Plastics are available in attractive colours. 
2) They do not undergo corrosion. 
3) They are not affected by insects. 
4) They are light in weight 
5) They are cheap. 
6) They can be moulded into any shape easily. 
7) They are chemically inert. 
8) They have good abrasion resistance. 
9) They are good insulators of heat and electricity. 
<h3>Disadvantages: 
</h3>
1. Recycling of plastic is a costly process. 
2. Plastic undergo biodegradation after long time. 
3. Incineration of plastic causes pollution. 
4. Plastics are derived from petrochemicals and their production causes environmental pollution. 
5. Plastics are low heat resistance and brittle. They undergo deformation at low temperatures.
<h1 id="tpts">Differences between Thermo Plastics &Thermo Setting plastics :
</h1>
<a href={thermal2} download>  <img src={thermal2} />click to download</a> 
<hr/>
<a href={themalvsthermo} download>  <img src={themalvsthermo} />click to download</a> 

<h1 id="compoundplastics">Explain the compounding of plastics with suitable examples?</h1>
<p>Explain the compounding of plastics with suitable examples? 
Definition: “The process of mechanical mixing of various additives with polymers to impart some special properties to the 
end product is known as compounding of plastics”. The additives get incorporated into the polymer to give a homogeneous 
mixture. 
The principal additives used in compounding are; 1) Binders or Resins 2)Plasticizers 3)Fillers 
4)Lubricants 5)Activators or accelerators 6)Stabilisers 7)Colourants 
1) Binders or Resins: 
Resin is the binder which holds the other constituents of the plastics together and it is the major constituent. 
The binders used may be natural or synthetic resins with very high molecular mass. They undergo condensation and 
polymerization during moulding of plastics. The resin gives the desired properties like plasticity and electrical 
insulating properties to the plastic. 
2) Plasticizers: 
These are materials which are added to resins to increase their plasticity and flexibility. 
 commonly used plasticizers are tributyl phosphate, triphenyl phosphate, diiso-octyl phthalate, dibutyl phthalate etc. 
3) Fillers: 
Fillers are inert materials add to plastic to increase the bulk and there by to reduce the cost of production. 
and also to impart certain specific properties to the finished product. 
 Commonly used fillers are mica, silica, graphite, carbon-black (C-black), chalk, china clay, clay, wood flavour etc. 
4) Lubricants: 
 Lubricants such as oils, waxes, stearates, soaps etc help in easy moulding and give better glossy finish.
 Prevent moulded article from sticking to the fabrication equipment.
5) Catalyst or Accelerators: 
These are used in the case of thermosettings plastics to accelerate the condensation polymerization to form the 
linked products. 
Examples , benzoyl peroxide, H2O2 , metals like Ag, Cu, Pb etc
6) Stabilizers: 
 Many plastics undergo thermal (or) photo chemical degradation during their processing (or) when they are put into use. 
 Stabilizers protect the plastic from such degradation. 
 Natural rubber, PE, esters undergo such degradation. 
 Examples : PbO, lead silicate, , lead chromate, stearates of Pb & Ba etc 
These are substances added to plastic to improve the thermal stability during moulding.</p>
7)Colourants : 
 These are inorganic (or) organic pigments used to impart pleasing colours to the plastic. 
 They don’t cause any influence on other properties.
 Ex; Carbon black, anthraquinones, azodyes, phthalocyamines, BaSO4, TiO2, PbCrO4, Fe2O3, Zinc chromate ,Organic 
dyestuff, opaque inorganic pigments. <hr/>
<h3>Methods of Fabrication of plastics: [or] methods of moulding of plastics: [or] Fabrication Techniques: </h3>
The process of converting the given polymeric material into suitable designs is called moulding. 
The different moulding methods used are 
a) Compression moulding b) Injection moulding c) Blow film 
d) Extrusion moulding
<h3>compress moulding</h3>
<a href={compress} download>  <img src={compress} />click to download</a> <hr/>
 Compression moulding method is applied to both thermoplastics and thermosettings plastics. 
 Compression moulding is one of the oldest manufacturing technologies associated with plastics. 
 The mould consists of two halves, the upper half and the lower half. 
 The lower half contains a cavity and the upper half has a projection. 
 The required amount of a compounded mixture is placed in the cavity in the lower half and the mould is closed 
carefully. 
 The gap between the projected upper half and the cavity in the lower half gives the shape of the moulded 
article. 
 The time given to polymer to set in the mould is called curing. 
 Curing is done either by heating as in the case of thermosetting or cooling as in the case of thermoplastics. 
 After curing, the moulded article is taken out by opening the mould parts.Now a days fully automatic compression 
moulding presses are available. 
Injection moulding: 
 Injection moulding method is mainly used for thermoplastic resins. 
 This process is one of the most common of all plastics manufacturing processes. 
 The compounded mixture in the form of a powder (or) pellet is fed into the heated cylinder of the injection 
moulding machine. 
www.Jntufastupdates.com
 Unit I Polymer Technology Page 7 
 The softened plastic mass in the cylinder is injected at a controlled rate by a screw (or) piston under high pressure 
into a cold mould. 
 Curing of the softened plastic into rigid product occurs during cooling in the mould. 
 The mould is opened after sufficient curing to remove the finished product. 
 Thermosetting polymer can’t be moulded by this method.
<a href={injmould} download>  <img src={injmould} />click to download</a> <hr/>
2. Blow Moulding: Blow moulding produces hollow plastic materials like bottles, tubes, tanks and drums. 
Thermoplastic materials like PVC, polysty- rene, polypropylene can be blow moulded. 
In this process a tube is placed inside a two piece hollow mould. One end of the tube is completely closed in heated and 
simultaneously air is blown to fabricate the product having the shape of mould
<a href={blow} download>  <img src={blow} />click to download</a> <hr/>
<h3>Extrusion moulding:</h3> 
 Extrusion moulding method is used for thermoplastic resins 
 This method is used to produce continuous sheets, rods, threads, tubes, cords and cables. 
 It is similar to injection moulding. 
 Dry plastic material is placed into heated injection chamber. 
 At the end of the chamber, the material is forced out of a small ring opening (or) a die in the shape of the desired 
finished product. 
 Extruded out plastic is placed on a moving conveyor belt for uniform cooling. 
<a href={blow} download>  <img src={blow} />click to download</a> <hr/>
<h1 id="pvc">Write preparation, properties and applications of i) PVC. ii) Polycarbonates. iii)Bakelite.
</h1>
<p>i)PVC (Poly vinyl chloride): 
Preparation: Poly vinyl chloride is produced by heating vinyl chloride in presence of benzyl peroxide or H2O2.
<a href={pvc} download>  <img src={pvc}  height='300px'/>click to download</a> <hr/>
Properties: PVC is colourless, odourless, non-inflammable and chemically inert powder. Pure resin possesses greater 
stiffness and rigidity compared to poly ethylene. It is brittle in nature.
Applications: or Uses: 
1. P.V.C is mainly used as an insulating material. 
2. It is used for making table clothes, rain coats, toys, tool handles, radio components, etc. 
3. It is used for making pipes, hoses, etc. 
4. It is used for making helmets, refrigerator components, etc. 
5. It is used in making cycle and automobile parts. <hr/>
ii) Polycarbonates: 
Preparation:
<a href={pc} download>  <img src={pc} height='300px' />click to download</a> <hr/>
Properties: 
1. High melting points 
2. Tensile strength and impact resistance. 
3. It has excellent mechanical properties. 
4. It is soluble in acids and alkali. 
Uses: The polymer is used in the manufacture of safety goggles, telephone parts, automobile taillight lenses and 
unbreakable glazing appliances.</p>
<h1 id="recyclinge"></h1>
<p><h3> Write a note on recycling of e-plastic waste. </h3>
e-plastic waste (electronic plastic waste) includes discarded electrical or electronic divices. Some examples of e plastic 
waste are ABS (acrylonitrile butadiene styrene), polycarbonate or PVC (polyvinyl chloride), phenol formaldehyde, 
polyamides etc.E-plastics are an environmental hazard, and have negative effects on human heath. 
Recycling e-plastics is challenging. This is because most plastics are not truly recycled. Some plastics can be melted 
down and used to manufacture new items, but most kinds of plastics are not recycled in this way. Instead, many plastic 
recycling facilities prepare the plastic to be repurposed, or “down-cycled.” For example, a plastic milk bottle cannot be used 
to manufacture a new plastic milk bottle. Instead, that milk bottle can be processed and “down-cycled” in the manufacturing 
of plastic lumber. E-plastics are so challenging to recycle because the brominated flame retardants prevent these plastics 
from being down-cycled. The e-plastics that contain those banned compounds cannot be reused; they must be disposed.
When recycling e-plastics, it is especially crucial to use a certified recycler. To maintain certification, a certified 
recycler must make sure that, when possible, e-plastics are processed and reintegrated back into the manufacturing process. 
All un-usable e-plastics, like those with banned brominated flame retardants, must disposed in carefully controlled 
conditions in a properly equipped facility.
Because e-plastics are so difficult to recycle, a better environmental strategy for this material is to reuse electronics. 
Reuse slows the production of scrap e-plastics, and mitigates the need for more plastics to be manufactured. If reusing your 
corporate electronics is not possible within your own company, reselling or donating the equipment allows it to be reused by 
others.</p>
<h1 id="Elstomers">ELASTOMERS: 
</h1>
<p> Definition: Elastomers are high polymers that undergo very long elongation (500 – 1000%) under stress, yet regain original 
size fully on released of stress. Those rubbers are therefore referred to as elastomers. 
The property of elastomers is known as elasticity. This arises due to the coiled structure of elastomers.
<h2 id="naturalrubber">Natural rubber:</h2>
Natural rubber is found in several species of rubber trees grown in tropical countries, of these Hevea brasiliensis is the most 
important source of natural rubber and the rubber obtained from this is known as “Hevea” rubber. Rubber is stored in the 
form of white fluid called ‘latex’ behind the bark of the rubber tree.
Natural rubber is a high polymer of isoprene (2-methyl-1,3-butadiene). 
Polyisoprene exists in two geometric isomers cis and trans forms. 
Natural rubber is soft and has cis-configuration while ‘Guttapercha’ or, ‘Ballata’ has trans configuration.
Properties: 
1. Styrene rubber is a strong and tough polymer. 
2. It is vulcanised by sulphur monochloride (S2CI2) or sulphur. 
3. It resembles natural rubber in processing characteristics and quality of finished products. 
4. BUNA - S possess excellent abrasion resistance. 
5. It is a good electrical insulator. 
6. It is resistant to chemicals but swells in oils and attacked by even traces of ozone, present in the atmosphere. 
7. Styrene rubber possess high load bearing capacity and resilience. 
Applications: 
1. Major application of styrene rubber is in the manufacture of tyres. 
2. It is used in the footwear industry for making shoe soles and footwear components. 
3. It is also used for making wires and cable insulations. 
4. The other applications of styrene rubber are for the production of floor files, tank linings in chemical industries and as 
adhesives. 
Thiokol (Polysulphide Rubber or GR-P): 
Polysulphide rubbers are the condensation product of ethylene dichloride and sodium tetra sulphide. 
Preparation : 
Properties: 
Thiokol rubber possess strength and impermeability to gases. 
This rubber cannot be vulcanized and it cannot form hard rubber. 
Possess good resistance to mineral oils,fuels,oxygen,solvents,ozone and sunlight. 
It is also resistant to the action of petrol lubricants and organic solvents 
Applications: 
Fabrics coated with thiokol are used for barrage balloons,life rafts and jackets. 
Thiokols are used for lining for canveying gasolin and oil. 
Used for making gaskets and seals for printing rolls. 
Thiokol mixed with oxidizing agents in used as a fuel in rocket engine</p>
<h1 id="frp">Fibre Reinforced Plastics(FRP): </h1>
<p>
Reinforcing a plastic matrix with a high strength fibre material results in the formation of fibre reinforced plastics. 
FRP contains two components. 1) Fibre. 2) Matrix. 
1)Fibre: 
www.Jntufastupdates.com
 Unit I Polymer Technology Page 15
Fibers are used to strengthen thermoplastic compounds. 
There are three main types of fibers carbon, glass & Aramid fibers. 
They have different properties. 
All fibers have generally high stress capacity than the ordinary steel. 
2) Matrix: 
The plastic material used in FRP is called matrix. 
Matrix is a tough and relatively weak plastic that is reinforced by stronger fibers. 
Generally thermosetting plastics are used as matrix. 
Vinyl esters and epoxy resins are most commonly used matrixes. 
Glass fibre - reinforced polymer(GFRP): 
Glass fibre + Polymer  GFRP 
Glass fibre - reinforced polymer composites employ glass fibres for improving the characteristics of especially polymeric 
matrices containing nylons, polyesters etc. These composites posses lower densities higher tensile strengths and impact 
resistance and resistance to corrosion and chemicals. 
Applications: Automobile parts, storage tanks transportation industries, plastic pipes etc., 
Carbon fibre - reinforced polymer(CFRP): 
Carbon fibre + Polymer  CFRP 
Carbon fibre - reinforced polymer composites are employed in situations requiring excellent resistance to corrosion, lighter 
density retention of properties even at high temperatures. 
Carbon fiber reinforced composites are very strong and are often stronger than steel but lighter. They are used for making 
parts of aeroplanes and the space shuttle, tennis rackets and golf clubs, weaving machines, missiles, agricultural etc. 
Aramid fiber reinforced plastics [bullet proof plastic]: 
 Aramid is a short form of aromatic polyamide. 
 Ex: Nomex & Kevlar. 
 These are used to make bullet proof vests & bicycle tyres. </p>
<h1 id="conductingpolymers">CONDUCTING POLYMERS: </h1>
<p>
A polymer which conducts electricity is called conducting polymer. 
Eg :- Polyaniline, polyacetylene, polypyrrole, etc 
They are classified into two types : 1) Intrinsically conducting polymers. 
2) Extrinsically conducting polymers 
1. Intrinsically conducting polymers: 
These have extensive conjugation in the backbone which is responsible for conductance. These polymers can be divided 
into two : 
a) Conducting polymers having conjugated π – electrons in the backbone: 
eg :- polyacetylene, polyaniline etc. 
These type of polymers have backbones of continuous sp2 hybridized carbon centers. One valence electron on each center 
resides in a Pz orbital. Overlapping of conjugated π – electrons over the entire backbone results in the formation of valence 
bonds as well as conduction bands, which extends over the entire polymer molecule. But since the valence band and the 
conduction band are separated by a significant band gap, conductivity of these polymers is not very high.
b) Doped conducting polymers: 
Conductivities of polymers having conjugated π – electrons in the backbone can be increased by 
creating either +ve or –ve charges on the polymer backbone by oxidation or reduction. This process is called doping. 
It can be done in two ways :
i)Oxidative doping (P – doping ): 
It involves treating the conjugated polymer with a Lewis acid like FeCl3 thereby oxidation takes 
place and +ve charges are created on the back bone. 
ii) Reductive doping (n – doping): 
It involves treating the polymer with a Lewis base like RNH2 thereby reduction takes place and -ve charges are created on 
the polymer back bone.
2.Extrinsically conducting polymers: 
 Some of the polymers conduct electricity due to externally added ingradients to them. They are of two types. 
a) Polymers with conductive elements filled Polymers. b) Blended conducting polymers. 
a).Polymers with conductive elements filled: 
In these polymers, the polymer acts as a ‘binder’ and holds the conducting element added so that the polymer becomes a 
conductor. Examples of conductive elements are carbon black, metallic fibers, metallic oxides etc. 
Minimum concentration of conductive element to be added so that the polymer becomes a conductor is called percolation 
threshold. The conductive elements added to create a conducting path in the polymer. 
b).Blended conducting polymers: 
These polymers are obtained by blending a conventional polymer with a conducting polymer. 
The polymer thus obtained has good chemical, physical, electrical properties and mechanical strength. 
Ex; 40% pyrrole when blended with a conventional polymer, the combination gives conducting polymer with good impact 
strength.
pplications of conducting polymers in biomedical devices: 
 Conducting polymers are used in preparation of artificial devices like Heart valves, kidneys, and lungs. 
 Poly methyl methacrylate is used as bone cement used for some fracture repairs. 
 Poly methyl methacrylate is also used for artificial teeth. 
 Used in preparation other medical devices include sutures, pins, screws used during surgery on bones, ankles, hands 
etc. 
 They are used to prepare contact lenses which permit O2 to the eyes. These lenses are called rigid gas permeable 
lenses (RGP). 
 </p>
 <h1 id="biodegradable">Write a note on Biodegradable polymers. </h1>
 <p>
 Biodegradation is the chemical breakdown of materials by physiological environment. 
 Some polymers undergo degradation when exposed to moisture, heat, oxygen, ozone and micro organism. 
 These agents change the chemical structure of the polymer and lead to change in properties of polymer. 
 Generally materials obtained from plants, animals and other living organism and synthetic materials similar to plant 
and animal material undergo degradation by microorganism. 
 These organic materials can be degraded either aerobically or anaerobically. 
 During biodegradation, microorganism secrets biosurfactant which facilitates degradation easily. 
 These microorganisms can perform various activities like degradation, transformation or accumulation of 
compounds like hydrocarbons, poly aromatic hydrocarbons, pharmaceutical substances, radio nucleids and metals. 
 The activity of aerobic bacteria is measured by the amount of O2 consumed or the amount of CO2 produced. 
 The activity of anaerobic bacteria is measured by the amount of CH4 gas produced.
 Properties: 
 These are non – toxic. 
 They are able to maintain good mechanical integrity until degraded. 
 Capable of controlled rates of degradation. 
 Applications: 
 It is used in drug delivery systems. 
 It is used to coat a stent and release drugs in a controlled way. 
 It is used in dental devices and orthopedic fixation devices. 
 Ex: Poly lactic acid, Poly glycolic acid, Poly hydroxyl butyrovelarate (PHBV). </p>
<h1 id="biopolymers">Write a short notes on Biopolymers.</h1>
<p> 
Biopolymers are naturally occuring long chain molecules which are involved in biological changes important for our lives. 
These polymers are present in living matter. 
Biopolymers are classified into four types. They are 
a. Carbohydrates 
b. Proteins 
c. Lipids 
d. Nucleic acids. 
Carbohydrates: 
Carbohydrates are a group of compounds represented by the general formula, Cx(H2O)y
Carbohydrates are classified into following classes depending upon whether these undergo hydrolysis and if so on the 
number of products form: Monosaccharides, Disaccharides, Trisaccharides, Oligosaccharides, Polysaccharides. 
1) Monosaccharides (also known as simple sugars) are the simplest carbohydrates containing 3-7 carbon atoms. A 
sugar containing: – an aldehyde is known as an aldose – a ketone is known as a ketose. 
2) Disaccharides :When two monosaccharides are combined together with elimination of a water molecule it is called 
disaccharide. Monosaccharides are combined by glycosidic bond. 
3) Oligosaccharides contain 2-10 monosaccharides bonded together (building block = residue) Example: Sucrose 
4) Polysaccharides: Polysaccharides are polymerized products of many monosaccharide units. 
Proteins: 
Proteins are complex nitrogeneous organic compounds of high molecular masses, synthesized by plants and animals, which 
on hydrolysis yield amino acids. Proteins are essential for the growth and maintenance of life. 
Role of proteins: 
1) They serve as fuel to yield energy.</p><hr/>
<h1 id="hydrogenelectrode">Explain the construction and working of Hydrogen electrode:
</h1>
<p>Construction:
 A small platinum foil coated with platinum black is connected to a platinum wire is enclosed in a glass tube.
 Platinum electrode is placed in a solution of H+
ions of 1M concentration.
 H2 gas with 1 atm pressure is passed over platinum electrode through the solution at 25oC.
 This whole assembly is considered as hydrogen reference electrode.
Using hydrogen electrode PH
of the solution can be determined.
    g
aq HeH 2
2
1

 
Applying Nernst equation to the above equilibrium
 
 
    
 
  
 
H
H
E P
P H
E ionsforHnH
n H
E
H
n H
E
H
H
nF
RT EE
.0 059
log
.0 059 log 1
1
log .0 059
1
1
log
96500
.2 303 .8 298314
0
log .2 303
2
2
1
0 2


 







 






Measurement of PH
using hydrogen electrode:
The above half cell (left side) placed in the solution of unknown PH
is connected to NHE through salt bridge.
The EMF of the cell is measured by using potentiometer.
 
.0 059
.0 059
.00 059
H cell
H
cell
H
cell
cell right left
E
P
E P
E P
EEE




Limitations:
1. Hydrogen electrode can’t be used in the
solutions containing compounds of Hg, As, S
and oxidizing agents like Fe3+, Cr2O7
2-
, and
MnO4-
.
3. If can’t be used in the presence of ions of many
metals.
4. It is difficult to set up a hydrogen electrode. It
cannot be used in solutions containing redox
systems.
<a href={hydrogen} download>  <img src={hydrogen} />click to download</a> 

</p>
<h1 id="calomel electrode">Q 4) Explain the construction and working of Calomel electrode (Secondary reference electrode):
</h1>
 Sometimes calomel electrode is used as reference 
electrode instead of NHE or SHE.
 The potential of saturated calomel electrode is 0.2422V.
Construction:
 A glass tube is filled with a layer of mercury at the 
bottom.
 It is covered with a paste of Hg + Hg2Cl2 and 
saturated KCl solution.
 A platinum wire is placed in the mercury layer for 
making electrical contact.
 By combining calomel electrode with H2 electrode, PH
of 
unknown solution can be determined.
 
H
cell
H
cell
cell right left
E P
E P
EEE
059.02422.0
059.02422.0



Ion Selective Electrode: (ISE):
Ion Selective Electrodes (ISE) use a membrane which is sensitive to a particular chemical species.ISE respond to 
certain specific ions present in a mixture while ignoring others and develop potential.The potential developed is a 
measure of the concentration of the species of interest.
Examples: Glass electrode and Fluoride electrode<hr/>
<a id="drycell" href={chemscreen} download>  <img src={chemscreen} />click to download</a> <hr/>
<hr/>
<a id="lithiumion" href={lion} download>  <img src={lion} />click to download</a> <hr/>
<hr/>
<a id="fuelcell" href={fuel} download>  <img src={fuel} />click to download</a> <hr/>
<hr/>
<a id="Corrosion" href={corrosion} download>  <img src={corrosion} />click to download</a> <hr/>
<hr/>
<a id="Galvaniccorrosion" href={galvaniccorrosion} download>  <img src={galvaniccorrosion} />click to download</a> <hr/>
<hr/>
<a id="Galvanicseries" href={galvanicseries} download>  <img src={galvanicseries} />click to download</a> <hr/>
<hr/>
<a id="Specialpaints" href={specialpaints} download>  <img src={specialpaints} />click to download</a> <hr/>
<hr/>
<a id="AdvanceConcepts" href={advanceConcepts} download>  <img src={advanceConcepts} />click to download</a> <hr/>
<hr/>
<a id="Advance" href={advance} download>  <img src={advance} />click to download</a> <hr/>
<hr/>
<a id="Advanceconcept2" href={advanceconcept2} download>  <img src={advanceConcepts} />click to download</a> <hr/>
<hr/>
<a id="Rotaxenesdef" href={rotaxenes} download>  <img src={rotaxenes} />click to download</a> <hr/>
<hr/>
<a id="Rotaxenes3" href={rotaxenes3} download>  <img src={rotaxenes3} />click to download</a> <hr/>
<hr/>




        </pages>


    </div>
    </div>
  
  </>




)

 }
 export default Chem;