import S1 from  './weavelifec1.jpg'
import BasicExample from './signup'
import s1 from './probabilty and ststistics.jpg'
import s2 from './python screen2.png'
import s3 from './pythonscreen3.png'
import psNotes from '../src/probabilty and statistics_removed.pdf'

import Image from './ImageSlide'
const East = ()=>{
 return(

     <div style={{backgroundColor:"white"}}>
        <Image image1={s1} image2={s2} image3={s3} />
        <h1 style={{color:"black",textAlign:"center", textShadow:"1px 1px 20px yellow"}}>preview</h1>

        <BasicExample name={psNotes} value="operatingSystem" type="Download" image={S1}/>   



     </div>
    


 )



    }
    export default East;