import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

class DarkVariantExample extends React.Component {
    render(){
  return (
    <Carousel data-bs-theme="dark" style={{margin:"10px",padding:"1px" ,border:"10px  solid black" }}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.props.image1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>First slide label</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.props.image2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Second slide label</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.props.image3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
}


export default DarkVariantExample;