import React from "react";
import BasicExample from "./signup";
import S1 from  './weavelifec1.jpg'
import S2 from './weavelifec2.jpg'
import S3 from './weavelifeC3.jpg'
import S4 from './weavelifec4.jpg'
import S5 from './weavelifec5.jpg'
import unit1 from '../src/UNIT_1_Introduction_to_Programmming_and_Problem_Solving.pdf'
import unit2 from '../src/UNIT_2_Control_Structures.pdf'
import unit3 from '../src/UNIT_3_Arrays_Strings.pdf'
import unit4 from '../src/UNIT_4_Pointers_Userdefineddatatypes (1).pdf'
import unit5 from '../src/UNIT_5_Functions_FilesHandling.pdf'
const East = ()=>{
 return(

     <div>
        <BasicExample name={unit1} value="C-language" type="Download" image={S1}/>   
        <BasicExample name={unit2} value="C-language" type="Download" image={S2}/>   
       <BasicExample name={unit3} value="C-language" type="Download" image={S3}/>   
       <BasicExample name={unit1} value="C-language" type="Download" image={S4}/>   
       <BasicExample name={unit1} value="C-language" type="Download" image={S5}/>   



     </div>
    


 )



    }
    export default East;