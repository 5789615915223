import React from 'react'
import Learn from './learn'
import { useState } from 'react'
const Home =()=>  {

  const[name,setname]=useState("about-us")
   
  return (
    <div className='home'>
       <h1 style={{
        textDecoration:"none",
        color:"black",
        textAlign :"center",
        textShadow:"1px 1px 20px  green",
        padding:"120px",
        fontFamily:"cursive"

       }}>Learn From WeaveLife</h1>

       <div className='boxtext'>
       <a href="/Learn"><button   className='glow'> Getstarted  </button> </a>

       </div>
       <div className='adressbar'>
       <div className='footerelements'><a href='mailto:tejuteja371@gmail.com'>Contact-us</a>
           </div>  
        <div className='footerelements' onClick={()=>setname("https://www.instagram.com/teja_bhojanapu?igsh=MWxuN2NhMW1pYjN4dg== ")}> <a href={name}> {name}</a>
          </div>
        <div className='footerelements'>&copy;Weavelife2024
      
      </div>  
      <div className='footerelements'>More on Weavelife
      
      </div>  
      
      </div>  

      
         
       </div>



  )
}


export default Home