import React from 'react'
import BasicExample2 from './login';
import sub2 from './M1.jpg'
import sub3 from './chemfinal.jpg'
import sub4 from './aphys.jpg'
import sub18 from './ultimatec.jpg'
import sub5 from './M2.jpg'
import sub6 from './coa.jpg'
import sub7 from './What-is-Data-Structures-and-Algorithms.jpg'
import sub8 from './Os.jpg'
import sub9 from './software-engineering-chart-with-keywords-and-icons-on-blackboard-G9R7A.jpg'
import sub10 from './mfcs.jpg'
import sub11 from './probabilty and ststistics.jpg'
import sub12 from './Database-Management-System.jpg'
import sub13 from './Rprogramming.jpg'
import sub14 from './Machinelearning.jpg'
import sub15 from './cn.jpg'
import sub16 from './Daa.jpg'
import sub17 from './DeepLearning.png'
import Image1 from './kgf1.jpg'
import sub1 from './english_communication_bodyimage (1).jpg'



const EEE=()=> {
  return (
        
   <><h1 style={{textAlign:"center",fontFamily:"cursive",color:"black",  textShadow:"1px 1px 21px yellow"}}> 1-1  EEE</h1>

         <div className='1-1cse' style={{width:"100%",height:"400px", backgroundColor:"white", display:"inline-block", overflow:"scroll" ,color:"white"}}>
         <BasicExample2 Image={sub1} url="/East" value="English" type="Lets..Read" text="English "/>
         <BasicExample2 Image={sub2} url="/hjhf" value="Mathematics1" type="Lets..Read" text="mathematics "/>
          <BasicExample2 Image={sub3} url="/Chem" value="chemistry" type="Lets..Read" text="chemistry "/>
          <BasicExample2 Image={sub18} url="/login" value="C language" type="Lets..Read" text="C language"/>

         </div>
         <h1 style={{textAlign:"center",fontFamily:"cursive",color:"black",padding:"5px" ,textShadow:"1px 1px 21px yellow"}}> 1-2  EEE</h1>
         <div className='1-2cse' style={{width:"100%",height:"400px", backgroundColor:"white", display:"inline-block", overflow:"scroll" ,color:"white"}}>

         <BasicExample2 Image={sub3} url="/Chem" value="Applied chemistry" type="Lets..Read" text="Chemistry "/>
         <BasicExample2 Image={sub6} url="/hjhf" value="COA" type="Lets..Read" text="Computer Organization Architecture "/>
         <BasicExample2 Image={sub7} url="/hjhf" value="DataStructures" type="Lets..Read" text="DataStructures "/>

        </div>
        <h1 style={{textAlign:"center",fontFamily:"cursive",color:"black",  padding:"5px",textShadow:"1px 1px 21px yellow" }}> 2-1  EEE</h1>
        <div className='2-1cse' style={{width:"100%",height:"400px", backgroundColor:"white", display:"inline-block", overflow:"scroll" ,color:"white"}}>

    <BasicExample2 Image={sub8} url="/hjhf" value="Applied chemistry" type="Lets..Read" text="Chemistry "/>
     <BasicExample2 Image={sub9} url="/hjhf" value="COA" type="Lets..Read" text="Computer Organization Architecture "/>
      <BasicExample2 Image={sub10} url="/hjhf" value="DataStructures" type="Lets..Read" text="DataStructures "/>

</div>
<h1 style={{textAlign:"center",fontFamily:"cursive",color:"black",  padding:"5px", textShadow:"1px 1px 21px yellow" }}> 2-2 EEE</h1>
        <div className='2-2cse' style={{width:"100%",height:"400px", backgroundColor:"white", display:"inline-block", overflow:"scroll" ,color:"white"}}>

    <BasicExample2 Image={sub11} url="/hjhf" value="Probabilty" type="Lets..Read" text="Probabilty "/>
     <BasicExample2 Image={sub12} url="/hjhf" value="DBMS" type="Lets..Read" text="DBMS "/>
      <BasicExample2 Image={sub13} url="/hjhf" value="DataStructures" type="Lets..Read" text="DataStructures "/>

</div>
      <h1 style={{textAlign:"center",fontFamily:"cursive",color:"black", padding:"5px"  ,textShadow:"1px 1px 21px yellow"}}> 3-1 EEE</h1>
        <div className='3-1cse-AI' style={{width:"100%",height:"400px",  display:"inline-block", overflow:"scroll" ,color:"white"}}>

    <BasicExample2 Image={sub14} url="/hjhf" value="Probabilty" type="Lets..Read" text="Probabilty "/>
     <BasicExample2 Image={sub15} url="/hjhf" value="DBMS" type="Lets..Read" text="DBMS "/>
      <BasicExample2 Image={sub17} url="/hjhf" value="DataStructures" type="Lets..Read" text="DataStructures "/>


</div>





   </>










  )

   }

export default EEE;