import React from "react"
import waveoptics1 from './coaintroduction.png'
import waveoptics2 from './coa2.png'
import newtonring from './coaBusstructure.png'
import newtonrings2 from './coa&bufferregister.png'
import diffraction from './coadatatypes.png'
import diffraction1 from './coadatatype2.png'
import polarization from './coacomplements.png'
import dielectrics from './coafixedpoint.png'
import ferroelectrics from './coaerrordetection.png'
import softhardmagnetics from './coacomputerarithmetic.png'
import softhardmagnets2 from './coaarithmetic2.png'
import ferromagnetic from './coadivisionalgo.png'
import ferromagnetic2 from './coadivision2.png'
import coaunit2 from './coaunit2-1.png'
import coaunit21 from './coaunit2-1.png'
import Busmt from './BusandmemoryTransfer.png'
import Busmt2 from './Busandmemory2.png'
import Amop from './Arithmeticmicrooperationscoa2.png'
import binc from './BinaryIncrementcoa2.png'
import computerInstruction2 from './computerinstructions2coa2.png'


const Coa = () => {
  return (
    <>
        <div className="learning">
          <div className="sidebar">
     <ol>
     <li><a>Subject</a>
     <ul>
        <li>Chapter-1</li>
        <li><a href="#Waveoptics1">Introduction</a></li><hr/>
     <li><a href="#NewtonsRing">BusStructure</a></li><hr/>
     <li><a href="#Diffraction">Datatypes</a></li><hr/>
     <li><a href="#Polarization">Complements</a></li><hr/>
     

     <li>Unit2</li>
     <li><a href="#Dielectrics">FixedpointNotations</a></li><hr/>
     <li><a href="#Ferroelectrics">ErrorDetection</a></li><hr/>
     <li><a href="#Softhard">ComputerArithmetic</a></li><hr/>
     <li><a href="#Ferromagnetic">DivisionAlgorithm</a></li><hr/>
     <li>Unit2</li>

     <li><a href="#busmt">BusandmemoryTransfer</a></li><hr/>
     <li><a href="#amop">Arithmeticmicrooperations</a></li><hr/>
     <li><a href="#binc">BinaryIncrement</a></li><hr/>
     <li><a href="#computerinstru">ComputerInstructions</a></li><hr/>
     <li>Unit3</li>
     <li><a href="#AdvanceConcepts">SomeAdvanceConcepts </a></li><hr/>
     <li><a href="#Rotaxenesdef">rotaxenes</a></li><hr/>
     <li><a href="#AdvanceConcepts2">MoleculeSwitches</a></li><hr/>
     
     



     </ul>
    
     </li><hr/>
     </ol>

      </div>
      <div className="learnsubjects">
        <h1 id="introduction" style={{textAlign:"center",fontFamily:"cursive"}}>Welcome to COA</h1>
        
<a id="Waveoptics1" href={waveoptics1} download>  <img src={waveoptics1} />click to download</a> <hr/>
<hr/>
<a  href={waveoptics2} download>  <img src={waveoptics2} />click to download</a> <hr/>
<hr/>
<a  id="NewtonsRing" href={newtonring} download>  <img src={newtonring} />click to download</a> <hr/>
<hr/>
<a   href={newtonrings2} download>  <img src={newtonrings2} />click to download</a> <hr/>
<hr/>
<a  id="Diffraction" href={diffraction} download>  <img src={diffraction} />click to download</a> <hr/>
<hr/>
<a   href={diffraction1} download>  <img src={diffraction1} />click to download</a> <hr/>
<hr/>

<hr/>
<a id="Polarization"  href={polarization} download>  <img src={polarization} />click to download</a> <hr/>
<hr/>
<a id="Dielectrics"  href={dielectrics} download>  <img src={dielectrics} />click to download</a> <hr/>
<hr/>
<a id="Ferroelectrics"  href={ferroelectrics} download>  <img src={ferroelectrics} />click to download</a> <hr/>
<hr/>
<a id="Softhard"  href={softhardmagnetics} download>  <img src={softhardmagnetics} />click to download</a> <hr/>
<hr/>
<a   href={softhardmagnets2} download>  <img src={softhardmagnets2} />click to download</a> <hr/>
<hr/>
<a id="Ferromagnetic"  href={ferromagnetic} download>  <img src={ferromagnetic} />click to download</a> <hr/>
<hr/>
<a   href={ferromagnetic2} download>  <img src={ferromagnetic2} />click to download</a> <hr/>
<a id="coa"  href={coaunit21} download>  <img src={coaunit21} />click to download</a> <hr/>
<hr/>
<a   href={coaunit2} download>  <img src={coaunit2} />click to download</a> <hr/>
<hr/>
<a id="busmt"  href={Busmt} download>  <img src={Busmt} />click to download</a> <hr/>
<hr/>
<a   href={Busmt2} download>  <img src={Busmt2} />click to download</a> <hr/>
<hr/>
<a id="amop"  href={Amop} download>  <img src={Amop} />click to download</a> <hr/>
<hr/>
<a id="binc"  href={binc} download>  <img src={binc} />click to download</a> <hr/>
<hr/>
<a id="compterinstru"  href={computerInstruction2} download>  <img src={computerInstruction2} />click to download</a> <hr/>
<hr/>


      </div>
      </div>
      </>
    
  )
}

export default Coa