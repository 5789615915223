import { render } from '@testing-library/react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

class BasicExample2  extends React.Component {
  render(){
return (
  <Card className='card' style={{ width:"21rem",
      margin:"10px",
      display:"inline-block",
      padding:"-0px",
      overflow:"hidden",

   }}>
    <Card.Img variant="top" src={this.props.Image}   />
    <Card.Body>
      <Card.Title style={{color:"red",
      fontFamily:"cursive"
      }}>{this.props.value}</Card.Title>
      <Card.Text>
        Enjoy{this.props.text} with weavelife 
      </Card.Text>
      <a href={this.props.url} ><Button variant="primary" >{this.props.type}</Button>
      </a>
    </Card.Body>
  </Card>

);
}

}
export default BasicExample2;
