import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React from 'react';
class BasicExample  extends React.Component {
  render(){
return (
  <Card style={{ width: '21rem',
      margin:"10px",
      display:"inline-block",
      padding:"-0px",
      overflow:"hidden"
   }}>
    <Card.Img variant="top" src={this.props.image} download   />
    <Card.Body>
      <Card.Title style={{color:"red",
      fontFamily:"cursive"
      }}>{this.props.value}</Card.Title>
      <Card.Text>
        Some quick example text to build on the card title and make up the
        bulk of the card's content.
      </Card.Text>
      <a href={this.props.name} download={this.props.value}><Button variant="primary"  download>{this.props.type}</Button>
      </a>
    </Card.Body>
  </Card>

);
}

}
export default BasicExample;
