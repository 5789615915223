import { Pages } from "@mui/icons-material";
import React ,{useState}from "react";
import './form.css'
import axios from 'axios'
const Read=()=>{
  const[data,setdata]=useState({
    username:"",
    useremail:""
  })
const{username,useremail}=data;
const changeHandler = e =>{
  setdata({...data,[e.target.name]:e.target.value})
}
const submithandler= e =>{
  e.preventDefault();
  axios.post('https://weavelifeweb-default-rtdb.firebaseio.com/register.json',data).then(()=>alert("submitted successfully"))
}
return(


      <div style={{marginTop:"150px" ,marginLeft:"25dp", padding:"15px"}}>
          
          <form className="loginform" onSubmit={submithandler}>
            <fieldset>
               <legend>Signup/Login</legend>
            <label for="name" >Name:</label>
        <input className="login" type="text" name="username" value={username} onChange={changeHandler} required /><br/><br/>
        <label for="email" >Email:</label>
        <input className="login" type="email" name="useremail" value={useremail} onChange={changeHandler}  required /><br/><br/>
       <a href="/Learn"> <input className="btnlogin" type="submit" name="submit"  /> </a>
        </fieldset>
         








          </form>
      
      
      
      
      
      
      </div>

)










}
export default Read;