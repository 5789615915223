import React from 'react'
import Image1 from './kgf1.jpg'
import Image2 from './kgf2.jpg'
import { Switch,Route } from 'react-router-dom'
import BasicExample from './signup'
import Email from './Emaillogin'
import OffcanvasExample from './Navtest'
import Card from './downloads'
import Learn from './learn'
import Home from './home'
import Read from './reading '
import East from './EachSubject'
import data from './course.json'
import { useState } from 'react'
import Cse from './cse'
import Chem from './chemistry'
import Teju from './tejnav'
import CseAI from './cseAi'
import EEE from './eee'
import Mech from './mech'
import Civil from './civil'
import ECE from './ece'
import Htmlest from './htmlest '
import Android from './android'
import Css from './css'
import Sql from './sql'
import Java  from './java'
import Jscript from './javascript'
import Python from './python'
import Kotlin from './kotlin'
import Dsa from './DSA'
import Cloud from './cloud'
import { Dashboard } from '@mui/icons-material'
import Physics from './physics'
import Coa from './Coa'
import Reacts from './reac'
import Jquery from './jquery'
import Django from './django'
import Math from './maths'
import Os from './os'
import Soft from './softwareEngineer'
import Mfc from './mfc'
import Ps from './probabilty'
import Cn from './cn'
import Flat from './flat'
import Dl from './deeplearn'
import Ml from './maths'
import Eng  from './english'
function App() {

  return (

     

    <div className='app'>

      <OffcanvasExample/>
      


<div>

   <Switch>
    <Route path="/default" component={Home}/>
    <Route path="/model" component={BasicExample} /> 
    <Route path="/emaillogin" component={Email} /> 
    <Route path="/Learn" component={Learn} /> 
    <Route path="/cards" component={Card}/>
    <Route path="/home" component={Home}/>
    <Route path="/read" component={Read}/>
    <Route path="/cse" component={Cse}/>
    <Route path="/East" component={East}/>
    <Route path ="/Chem" component={Chem}/>
    <Route path="/ai"  component ={CseAI}/>
    <Route path="/eee"  component ={EEE}/>
    <Route path="/mech"  component ={Mech}/>
    <Route path="/ece"  component ={ECE}/>
    <Route path="/civil"  component ={Civil}/>
    <Route path="/html"  component ={Htmlest}/>
    <Route path="/android" component={Android}/>
    <Route path="/css" component={Css}/>
    <Route path="/sql" component={Sql}/>
    <Route path="/java" component={Java}/>
    <Route path="/jscript" component={Jscript}/>
    <Route path="/python" component={Python}/>
    <Route path="/kotlin" component={Kotlin}/>
    <Route path="/dsa" component={Dsa}/>
    <Route path="/cloud" component={Cloud}/>
    <Route path="/physics" component={Physics}/>
    <Route path="/coa" component={Coa}/>
    <Route path="/react" component={Reacts}/>
    <Route path="/django" component={Django}/>
    <Route path="/jquery" component={Jquery}/>
    <Route path="/math" component={Math}/>
    <Route path="/os" component={Os}/>
    <Route path="/soft" component={Soft}/>
    <Route path="/mfc" component={Mfc}/>
    <Route path="/ps" component={Ps}/> 
    <Route path="/cn" component={Cn}/>
    <Route path="/flat" component={Flat}/>
    <Route path="/dl" component={Dl}/>
    <Route path="/Ml" component={Ml}/>
    <Route path="/eng" component={Eng}/>
    <Route path="/app" component={App}/>


    
















    
     
    
    </Switch>  


 </div>
 </div>




  )
}

export default App