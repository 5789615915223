import BasicExample from "./signup";
import S1 from  './weavelifec1.jpg'
import s1 from './html-tagst.jpg'
import s2 from './htmlscreen2.png'
import s3 from './cssscreen1.png'
import html from '../src/HTML - Notes(original).pdf'
import Image from './ImageSlide'

const Htmlest = ()=>{
 return(

     <div style={{backgroundColor:"white"}}>
       <Image image1={s1} image2={s2} image3={s3} />
        <h1 style={{color:"black",textAlign:"center", textShadow:"1px 1px 20px yellow"}}>preview</h1>

        <BasicExample name={html} value="HTML" type="Download" image={S1}/>   
        


     </div>
    


 )



    }
    export default Htmlest;