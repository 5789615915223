import React from "react";

import BasicExample2 from "./login"; 
import sub2 from './M1.jpg'
import sub3 from './Appliedchemistry.png'
import sub4 from './applied physics.jpg'
import sub5 from './M2.jpg'
import sub6 from './coa.jpg'
import sub7 from './What-is-Data-Structures-and-Algorithms.jpg'
import sub8 from './Os.jpg'
import sub9 from './software-engineering-chart-with-keywords-and-icons-on-blackboard-G9R7A.jpg'
import sub10 from './mfcs.jpg'
import sub11 from './probabilty and ststistics.jpg'
import sub12 from './Database-Management-System.jpg'
import sub13 from './Rprogramming.jpg'
import sub14 from './Machinelearning.jpg'
import sub15 from './cn.jpg'
import sub16 from './Daa.jpg'
import sub17 from './DeepLearning.png'
import Image1 from './kgf1.jpg'
import android from './Android Studio.png'
import html from './html-tagst.jpg'
import LabelBottomNavigation from "./bottonnav";
import css from './cssfinal.jpeg'
import python from './python.jpeg'
import jscript from './javascript.png'
import Dsa from './DSA.jpeg'
import java from './java.jpeg'
import kotli from './kotlin.png'
import sql from './sql final.jpeg'
import cloud from './clo.jpg'
import C from './ultimatec.jpg'
import jquery from './jquery.png'
import react from './Reactjs.jpg'
import django from './django.jpg'
const Learn=()=>{

 return(

      <div className="learning">
      <div className="sidebar">
     <ul>
     <li><a href="/html">HTML</a></li><hr/>
     <li><a href="/css">CSS</a></li><hr/>
     <li><a href="/jscript">javaScript</a></li><hr/>
     <li><a href="/android">Android</a></li><hr/>
     <li><a href="/python">Python</a></li><hr/>
     <li><a href="/dsa">DSA</a></li><hr/>
     <li><a href="/sql">SQL</a></li><hr/>
     <li><a href="/java">Java</a></li><hr/>
     <li><a href="/kotlin">Kotlin</a></li><hr/>
     <li><a href="/cloud">CloudComputing</a></li><hr/>
     <li><a href="/jquery">Jquery</a></li><hr/>
     <li><a href="/react">React</a></li><hr/>
     <li><a href="/django">Django</a></li><hr/>
     <li><a href="/East">C</a></li><hr/>

     
     


     </ul>

      </div>
      <div className="learnsubjects">
   <BasicExample2  Image={html} url="/html" value="HTML" type="Download" text="HTML "/>
   <BasicExample2   Image={android}  url="/android" value="Android" type="Download" text=" Android"/>
   <BasicExample2 Image={C} url="/East" value="C" type="Download" text="C "/>
   <BasicExample2 Image={java}  url="/java" value="Java" type="Download" text="Java"/>
   <BasicExample2 Image={css}  url="/css" value="CSS" type="Download" text="Css"/>
   <BasicExample2 Image={cloud}  url="/cloud" value="Cloud Computing" type="Download" text="CloudComputing"/>

   <BasicExample2 Image={python}  url="/python" value="Python" type="Download" text="Python"/>
   <BasicExample2 Image={kotli}  url="/kotlin" value="Kotlin" type="Download" text="Kotlin"/>
   <BasicExample2 Image={sql}  url="/sql" value="SQL" type="Download" text="Python"/>
   <BasicExample2 Image={Dsa}  url="/dsa" value="DSA" type="Download" text="DSA"/>
   <BasicExample2 Image={jquery} url="/jquery" value="Jquery" type="Download" text="Jquery "/>
   <BasicExample2 Image={jscript}  url="/jscript" value="JavaScript" type="Download" text="JavaScript"/>

   <BasicExample2 Image={react} url="/react" value="React" type="Download" text="React "/>
   <BasicExample2 Image={django} url="/django" value="Django" type="Download" text="Django "/>





   
      </div>



      </div>



 )

}
export default Learn;